import React, { useEffect, useState } from "react";
import "../siteInfo/SiteInfo.jsx";
import {
  LabelText,
  ButtonInput,
  TextInput,
  SelectInput,
} from "@emerson/dynamic-ui-public";
import "./DeviceDetails.css";
import useOnScreen from "../../customHooks/UseOnScreen.js";
import DeleteIcon from "../../icons/icon--global_delete.svg";

const DeviceDetails = (props) => {
  const {
    setActiveInStep,
    serviceTypeName,
    serviceName,
    sysInfoDetails,
    userDeviceList,
    setSelectedDeviceList,
    userSiteList,
    selectedDeviceList,
    setSelectedSite,
    selectedSite,
    setSystem,
    setStepMemoize,
    deviceData,
    setDeviceData,
    setReason,
    reason,
  } = props;
  const [maxLimitError, setMaxLimitError] = useState(false);
  const [data, setData] = useState([
    {
      id: 1,
      serialNumber: "",
      modelCode: "",
      manufacturer: "",
      tagNumber: "",
      quality: "",
    },
    {
      id: 2,
      serialNumber: "",
      modelCode: "",
      manufacturer: "",
      tagNumber: "",
      quality: "",
    },
    {
      id: 3,
      serialNumber: "",
      modelCode: "",
      manufacturer: "",
      tagNumber: "",
      quality: "",
    },
    {
      id: 4,
      serialNumber: "",
      modelCode: "",
      manufacturer: "",
      tagNumber: "",
      quality: "",
    },
    {
      id: 5,
      serialNumber: "",
      modelCode: "",
      manufacturer: "",
      tagNumber: "",
      quality: "",
    },
    {
      id: 6,
      serialNumber: "",
      modelCode: "",
      manufacturer: "",
      tagNumber: "",
      quality: "",
    },
    {
      id: 7,
      serialNumber: "",
      modelCode: "",
      manufacturer: "",
      tagNumber: "",
      quality: "",
    },
    {
      id: 8,
      serialNumber: "",
      modelCode: "",
      manufacturer: "",
      tagNumber: "",
      quality: "",
    },
    {
      id: 9,
      serialNumber: "",
      modelCode: "",
      manufacturer: "",
      tagNumber: "",
      quality: "",
    },
    {
      id: 10,
      serialNumber: "",
      modelCode: "",
      manufacturer: "",
      tagNumber: "",
      quality: "",
    },
  ]);
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [sysProject, setSysProject] = useState("");
  const [sysModel, setSysModel] = useState("");
  const [sysSerialNumber, setSysSerialNumber] = useState("");

  const productOptions = (ipArr) => {
    return ipArr?.map((product, ind) => {
      return {
        greyedOut: false,
        label: product.serial_number,
        title: product.serial_number,
        value: product,
      };
    });
  };
  const handleSysData = (event) => {
    const { name, value } = event.target;
    setSystem({
      ...system,
      [name]: value,
    });
  };
  const siteOptions = (ipArr) => {
    return ipArr?.map((customer, ind) => {
      return {
        greyedOut: false,
        label: customer.customer_name,
        title: customer,
        value: customer.customer_name,
      };
    });
  };
  const populateRow = (inputData) => {
    setData((prevData) => {
      const newData = [...prevData];
      const emptyRowIndex = newData.findIndex(
        (row) =>
          !row.serialNumber &&
          !row.modelCode &&
          !row.manufacturer &&
          !row.tagNumber &&
          !row.quality
      );

      if (emptyRowIndex !== -1) {
        newData[emptyRowIndex] = {
          ...newData[emptyRowIndex],
          serialNumber: inputData.serial_number,
          modelCode: inputData.base_model,
          manufacturer: inputData.brand,
          tagNumber:
            inputData.tag_no === null ? "Not Available" : inputData.tag_no,
          quality:
            serviceTypeName === "Return A Product" ||
            serviceName === "Calibration"
              ? ""
              : newData[emptyRowIndex].quality,
        };
      }

      // Add a new empty row if needed and if it has less than 10 rows
      if (
        newData.filter(
          (row) =>
            !row.serialNumber &&
            !row.modelCode &&
            !row.manufacturer &&
            !row.tagNumber &&
            !row.quality
        ).length < 10
      ) {
        newData.push({
          id: newData.length + 1,
          serialNumber: "",
          modelCode: "",
          manufacturer: "",
          tagNumber: "",
          quality: "",
        });
      }

      return newData;
    });
  };

  const handleInputChange = (e, rowIndex, columnName) => {
    const { value } = e.target;
    setData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex][columnName] = value;
      return newData;
    });
  };
  const handleDropdownChange = (e, rowIndex) => {
    const { value } = e;
    setData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex].quality = value;
      return newData;
    });
  };

  const handleDeleteRow = (rowIndex) => {
    setData((prevData) => {
      const newData = prevData.filter((_, index) => index !== rowIndex);
      // Add a new empty row at the end if there are less than 10 rows
      if (newData.length < 10) {
        newData.push({
          id: newData.length + 1,
          serialNumber: "",
          modelCode: "",
          manufacturer: "",
          tagNumber: "",
          quality: "",
        });
      }
      return newData;
    });
  };
  const hasDataInRow = (row) => {
    return (
      row.serialNumber ||
      row.modelCode ||
      row.manufacturer ||
      row.tagNumber ||
      row.quality
    );
  };

  // visibility check
  const handleVisibilityChange = () => {
    setStepMemoize(4);
  };
  const ref = useOnScreen(handleVisibilityChange);
  //visibilty check

  useEffect(() => {
    setSelectedDeviceList(
      data.filter((obj) => {
        return !(
          obj.serialNumber === "" &&
          obj.modelCode === "" &&
          obj.manufacturer === "" &&
          obj.tagNumber === "" &&
          obj.quality === ""
        );
      })
    );
    setDeviceData(data);
  }, [data]);
  useEffect(() => {
    selectedDeviceList.length > 0 && setData(deviceData);
  }, []);
  return (
    <>
      <div ref={ref}>
        {sysInfoDetails.includes("Device") ||
        serviceTypeName === "Return A Product" ? (
          <div className="deviceServiceRequest">
            <br />
            <br />
            <LabelText
              text={
                serviceTypeName === "Return A Product"
                  ? "Service and Device Details"
                  : "Device Details"
              }
              textClass="ddl-text--h3"
            />
            <br />
            <p>
              Add up to 10 devices that need to be serviced.
              <br />
              <br />
            </p>
            <div className="deviceServiceRequestBox">
              <div className="selectBoxdevice">
                {serviceTypeName === "Return A Product" && (
                  <SelectInput
                    label="Select a Site"
                    labelClass="app-content-label"
                    className="siteDetail"
                    name="critical"
                    value={selectedSite?.customer_name}
                    onChange={(o, s, m, h) => {
                      setSelectedSite(o.title);
                    }}
                    options={siteOptions(
                      userSiteList.length === 0
                        ? [{ customer_name: "SITE NOT FOUND" }]
                        : [
                            ...userSiteList?.data
                              ?.filter((item, index, self) => {
                                return (
                                  index ===
                                  self.findIndex(
                                    (t) =>
                                      t.gdm_customer_number ===
                                        item.gdm_customer_number &&
                                      `${t.ebs_customer_name || ""}${
                                        t.oracle_customer_acc_number
                                          ? ` - ${t.oracle_customer_acc_number}`
                                          : ""
                                      }` ===
                                        `${item.ebs_customer_name || ""}${
                                          item.oracle_customer_acc_number
                                            ? ` - ${item.oracle_customer_acc_number}`
                                            : ""
                                        }`
                                  )
                                );
                              })
                              .map((item) => ({
                                ...item,
                                customer_name: `${
                                  item.ebs_customer_name || item.customer_name
                                }${
                                  item.oracle_customer_acc_number
                                    ? ` - ${item.oracle_customer_acc_number} -`
                                    : ""
                                }${
                                  item.address_line_1
                                    ? `${item.address_line_1}`
                                    : ""
                                }${item.city ? `, ${item.city}` : ""}${
                                  item.state_prov ? `, ${item.state_prov}` : ""
                                }${item.country ? `, ${item.country}` : ""}${
                                  item.world_area ? `, ${item.world_area}` : ""
                                }${
                                  item.postal_code
                                    ? `, ${item.postal_code}`
                                    : ""
                                }`,
                              })),
                            { customer_name: "OTHER" },
                          ]
                    )}
                    placeholder="PLEASE SELECT"
                    warningMsg=""
                    required
                  />
                )}
                <SelectInput
                  label="Search Devices"
                  labelClass="app-content-label"
                  name="critical"
                  value={selectedDeviceList}
                  onChange={(e, c, d, f) => {
                    if (selectedDeviceList.length < 10) {
                      const selectedDevice = e.value;
                      const isDeviceAlreadySelected = selectedDeviceList.some(
                        (item) => item.unique_id === selectedDevice.unique_id
                      );

                      if (!isDeviceAlreadySelected) {
                        populateRow(selectedDevice); // Populate the first empty row with the selected device
                      }
                    } else {
                      setMaxLimitError(true);
                    }
                  }}
                  options={productOptions(
                    userDeviceList?.length > 0
                      ? userDeviceList?.filter((obj) =>
                          obj.device_category.includes("Measurement")
                        )
                      : [{ serial_number: "NO DEVICES FOUND" }]
                  )}
                  placeholder="Product"
                  warningMsg=""
                />
                {serviceTypeName === "Return A Product" && (
                  <TextInput
                    label="Reason for Return"
                    onChange={(e) => setReason(e.target.value)}
                    placeholder="Please provide the reason here."
                    required
                  />
                )}
              </div>
              {maxLimitError && (
                <p className="redError">You can't add more than 10 devices !</p>
              )}
              <table>
                <thead>
                  <tr className="theadCssOverride">
                    <th>
                      <div className="coloumn-design">
                        <LabelText
                          text="Serial Number"
                          textClass="ddl-text--h6"
                        />
                        <span className="field-required-span">*</span>
                      </div>
                    </th>
                    <th>
                      <div className="coloumn-design">
                        <LabelText text="Model Code" textClass="ddl-text--h6" />

                        <span className="field-required-span">*</span>
                      </div>
                    </th>
                    <th>
                      <div className="coloumn-design">
                        <LabelText
                          text="Manufacturer"
                          textClass="ddl-text--h6"
                        />
                        <span className="field-required-span">*</span>
                      </div>
                    </th>
                    <th>
                      <LabelText text="Tag Number" textClass="ddl-text--h6" />
                    </th>
                    {serviceName === "Calibration" && (
                      <th>
                        <LabelText text="Quality" textClass="ddl-text--h6" />
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td>
                        <TextInput
                          onChange={(e) =>
                            handleInputChange(e, rowIndex, "serialNumber")
                          }
                          value={row.serialNumber}
                        />
                      </td>
                      <td>
                        <TextInput
                          onChange={(e) =>
                            handleInputChange(e, rowIndex, "modelCode")
                          }
                          value={row.modelCode}
                        />
                      </td>
                      <td>
                        <TextInput
                          onChange={(e) =>
                            handleInputChange(e, rowIndex, "manufacturer")
                          }
                          value={row.manufacturer}
                        />
                      </td>
                      <td>
                        <TextInput
                          onChange={(e) =>
                            handleInputChange(e, rowIndex, "tagNumber")
                          }
                          value={row.tagNumber}
                        />
                      </td>
                      {serviceName === "Calibration" && (
                        <td>
                          <SelectInput
                            labelClass="app-content-label"
                            value={row.quality}
                            onChange={(o, s, m, h) => {
                              data[rowIndex].quality = h;
                            }}
                            options={[
                              {
                                greyedOut: false,
                                label: "Witness Inspection",
                                title: "Witness Inspection",
                                value: "witness_inspection",
                              },
                              {
                                greyedOut: false,
                                label: "5-point",
                                title: "5-point",
                                value: "5_point",
                              },
                              {
                                greyedOut: false,
                                label: "9-point",
                                title: "9-point",
                                value: "9_point",
                              },
                            ]}
                            warningMsg=""
                          />
                        </td>
                      )}
                      <td>
                        {hasDataInRow(row) && (
                          <img
                            onClick={() => handleDeleteRow(rowIndex)}
                            src={DeleteIcon}
                            alt="Icon"
                            className="deleteIcon"
                          />
                        )}{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div>
              <br />
              <br />
              <ButtonInput
                btnType=""
                label="CONTINUE"
                onClick={() => {
                  setActiveInStep(3);
                  setSelectedDeviceList(
                    data.filter((obj) => {
                      return !(
                        obj.serialNumber === "" &&
                        obj.modelCode === "" &&
                        obj.manufacturer === "" &&
                        obj.tagNumber === "" &&
                        obj.quality === ""
                      );
                    })
                  );
                }}
                disabled={
                  serviceTypeName === "Return A Product"
                    ? reason === "" || selectedDeviceList.length === 0
                    : selectedDeviceList.length === 0
                }
              />
            </div>
          </div>
        ) : (
          <>
            {" "}
            <div className="deviceServiceRequest">
              <br />
              <br />
              <LabelText text={"System Information"} textClass="ddl-text--h3" />
              <br />
              <p>
                Please give us a few details about your system.
                <br />
                <br />
              </p>
              <div className="systemSelectionMenu">
                <TextInput
                  label="SYSTEM MODEL"
                  onChange={(e) => {
                    setSysModel(e.target.value);
                  }}
                  placeholder={"ex:Liq Hyd Metering System"}
                  value={sysModel}
                  required
                />
                <TextInput
                  label="SERIAL NUMBER"
                  onChange={(e) => {
                    setSysSerialNumber(e.target.value);
                  }}
                  required
                  value={sysSerialNumber}
                />
                <TextInput
                  label="PROJECT NUMBER"
                  onChange={(e) => {
                    setSysProject(e.target.value);
                  }}
                  required
                  value={sysProject}
                />
              </div>
              <div className="butonContinue">
                <ButtonInput
                  btnType=""
                  label="CONTINUE"
                  onClick={() => {
                    setActiveInStep(3);
                    setSystem({
                      sysProject: sysProject,
                      sysModel: sysModel,
                      sysSerialNumber: sysSerialNumber,
                    });
                  }}
                  disabled={sysModel !== "" && sysProject !== "" ? false : true}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DeviceDetails;
